import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { datadogRum } from '@datadog/browser-rum';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { createStore } from './app/store';
import {
    BASE_URL_FOR_STYLING,
    CONVERSATION_UUID_PARAM_NAME,
    DUTCH_LANGUAGE,
    ENGLISH_LANGUAGE,
    FRENCH_LANGUAGE,
    GERMAN_LANGUAGE,
    JOB_BOARD_ID_PARAM_NAME,
    LANGUAGE_PARAM_NAME,
    POLISH_LANGUAGE,
    POST_APPLICATION_URL_PARAM_NAME,
    STYLING_ID_PARAM_NAME
} from './common/constants';
import {
    APPCAST_ID,
    CAREER_STRUCTURE_ID,
    CATERER_GLOBAL_ID,
    CATERER_ID,
    CW_JOBS_ID,
    IRISH_JOBS_ID,
    JOBS_IE_ID,
    JOB_BOARD_DATA,
    MILKROUND_ID,
    NI_JOBS_COM_ID,
    RETAIL_CHOICE_ID,
    STEPSTONE_AT_ID,
    STEPSTONE_BE_ID,
    STEPSTONE_DE_ID,
    STEPSTONE_FR_ID,
    STEPSTONE_NL_ID,
    STEPSTONE_PL_ID,
    TJG_ID
} from './common/constants/jobBoard';
import { registerFaIcons } from './common/registerResources';
import { getEffectiveEndpointUrl, getPostApplicationUrl } from './common/urlUtils';
import App from './mirage/App';
import { getUrlParam, setUrlParam } from './utils';

import { de } from './common/constants/languages/de';
import { en } from './common/constants/languages/en';
import { fr } from './common/constants/languages/fr';
import { nl } from './common/constants/languages/nl';
import { pl } from './common/constants/languages/pl';
import { ERROR, STATUS_PARAM_NAME } from './mirage/constants/applicationStatus';
import { handleConfigToolPreviewer, isDevMode } from './mirage/middlewares/configToolPreviewer';
import { runEpicMiddleware } from './mirage/middlewares/epicMiddleware';

if (process.env.NODE_ENV === 'production') {
    datadogRum.init({
        applicationId: `${process.env.REACT_APP_APPLICATION_ID}`,
        clientToken: `${process.env.REACT_APP_CLIENT_TOKEN}`,
        site: 'datadoghq.eu',
        service: 'mirage',
        env: `${process.env.REACT_APP_ENVIRONMENT_NAME}`,
        version: `${process.env.REACT_APP_VERSION}`,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        allowedTracingUrls: [
            url => {
                try {
                    const target = new URL(url);
                    const origin = new URL(window.location.origin);
                    return origin.origin === target.origin && target.pathname.startsWith('/api');
                } catch (e) {
                    return false;
                }
            }
        ]
    });

    datadogRum.setGlobalContextProperty('conversationId', getUrlParam(CONVERSATION_UUID_PARAM_NAME));
    datadogRum.startSessionReplayRecording();
}

type TJobBoardId = keyof typeof JOB_BOARD_DATA;

registerFaIcons();

const startReactApp = (
    jobBoardId: TJobBoardId,
    effectiveEndpointUrl: string | null,
    postApplicationUrl: string | null,
    language: string | null
) => {
    const preloadedState = {
        session: {
            effectiveEndpointUrl,
            postApplicationUrl,
            jobBoard: JOB_BOARD_DATA[jobBoardId]
        },
        language: getLanguageStrings(language)
    };

    const store = createStore(preloadedState);

    if (isDevMode()) {
        handleConfigToolPreviewer(store.dispatch);
    }

    runEpicMiddleware();

    const render = () => {
        ReactDOM.render(
            <Provider store={store}>
                <App />
            </Provider>,
            document.getElementById('root')
        );
    };
    store.subscribe(render);
    render();
};

const loadStyling = (callback: typeof loadTheme): Promise<TJobBoardId> =>
    new Promise(resolve => {
        const jobBoardId = (getUrlParam(JOB_BOARD_ID_PARAM_NAME) || '').toLowerCase() as TJobBoardId;
        const stylingId = getUrlParam(STYLING_ID_PARAM_NAME);
        const head = document.querySelector('head');

        if (BASE_URL_FOR_STYLING && stylingId && head) {
            const url = `${BASE_URL_FOR_STYLING}${stylingId}.css`;
            const link = document.createElement('link');

            link.onload = () => resolve(jobBoardId);
            link.onerror = () => resolve(callback(jobBoardId));
            link.href = url;
            link.type = 'text/css';
            link.rel = 'stylesheet';

            head.appendChild(link);
        } else {
            resolve(callback(jobBoardId));
        }
    });

const loadTheme = async (jobBoardId: TJobBoardId | null): Promise<TJobBoardId> => {
    switch (jobBoardId) {
        case TJG_ID:
            await import('./common/resources/styles/totaljobs.scss');
            break;
        case CATERER_ID:
            await import('./common/resources/styles/caterer.scss');
            break;
        case IRISH_JOBS_ID:
            await import('./common/resources/styles/irish_jobs_ie.scss');
            break;
        case JOBS_IE_ID:
            await import('./common/resources/styles/jobs_ie.scss');
            break;
        case RETAIL_CHOICE_ID:
            await import('./common/resources/styles/retailchoice.scss');
            break;
        case CW_JOBS_ID:
            await import('./common/resources/styles/cwjobs.scss');
            break;
        case CAREER_STRUCTURE_ID:
            await import('./common/resources/styles/careerstructure.scss');
            break;
        case NI_JOBS_COM_ID:
            await import('./common/resources/styles/ni_jobs_com.scss');
            break;
        case CATERER_GLOBAL_ID:
            await import('./common/resources/styles/catererglobal.scss');
            break;
        case MILKROUND_ID:
            await import('./common/resources/styles/milkround.scss');
            break;
        case STEPSTONE_DE_ID:
        case STEPSTONE_FR_ID:
        case STEPSTONE_PL_ID:
        case STEPSTONE_NL_ID:
        case STEPSTONE_BE_ID:
        case STEPSTONE_AT_ID:
            await import('./common/resources/styles/stepstone.scss');
            break;
        case APPCAST_ID:
            await import('./common/resources/styles/appcast.scss');
            break;
        default:
            throw Error(`Unsupported theme version: ${jobBoardId}`);
    }

    return jobBoardId;
};

const getLanguageStrings = (language: string | null) => {
    switch (language) {
        case POLISH_LANGUAGE:
            return pl;
        case GERMAN_LANGUAGE:
            return de;
        case FRENCH_LANGUAGE:
            return fr;
        case DUTCH_LANGUAGE:
            return nl;
        case ENGLISH_LANGUAGE:
        default:
            return en;
    }
};

const effectiveEndpointUrl = getEffectiveEndpointUrl();

const postApplicationUrl = getPostApplicationUrl(getUrlParam(POST_APPLICATION_URL_PARAM_NAME));

export const getLanguage = () => getUrlParam(LANGUAGE_PARAM_NAME) || ENGLISH_LANGUAGE;

const language = getLanguage();

loadStyling(loadTheme)
    .then(jobBoardId => startReactApp(jobBoardId, effectiveEndpointUrl, postApplicationUrl, language))
    .catch(() => {
        if (postApplicationUrl) {
            window.location.href = setUrlParam(postApplicationUrl, STATUS_PARAM_NAME, ERROR).toString();
        } else if (effectiveEndpointUrl) {
            window.location.href = effectiveEndpointUrl;
        }
    });
